// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/user+/not-found-page.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/user+/not-found-page.tsx");
  import.meta.hot.lastModified = "1725982852000";
}
// REMIX HMR END

import MainContainer from "~/components/MainContainer";
import { images } from "~/constant/images";
export default function NotFoundPage() {
  const goToHome = () => {
    window.location.href = window.location.origin;
  };
  return <MainContainer headerImage={images.logo}>
      <div className="flex flex-col justify-center items-center  text-center p-4">
        <div className="bg-white p-10 rounded-lg shadow-md">
          <h1 className="text-9xl font-bold text-red-500 mb-4">404</h1>
          <h2 className="text-2xl font-semibold text-gray-800 mb-2">
            Oops! Page Not Found
          </h2>
          <p className="text-lg text-gray-600 mb-6">
            {`The page you're looking for doesn't exist or has been moved.`}
          </p>
          <button onClick={goToHome} className="bg-green-500 text-white text-lg py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300">
            {`Return to Home`}
          </button>
        </div>
      </div>
    </MainContainer>;
}
_c = NotFoundPage;
var _c;
$RefreshReg$(_c, "NotFoundPage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;